import { CatchingPokemonSharp } from "@mui/icons-material";
import { Button, InputBase } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { useNavigate, useLocation } from 'react-router-dom';

const Login = () => {
  let urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token");
  // const { token } = useParams();
  const [email, setEmail] = useState();
  const [verificationCode, setVerificationCode] = useState();

  const navigate = useNavigate()



  const [step, setStep] = useState(1);
  const [isLoading, setLoader] = useState(true);
  const [msg, setMsg] = useState("");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const loginWithToken = async () => {
    setLoader(true);
    console.log(token);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/login_with_token", {
        token,
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          console.log(document.referrer)
          if(window.location == document.referrer || document.referrer == '' ){
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.delete("token");
            window.history.replaceState(
              {},
              document.title,
              currentUrl.toString()
            );
            window.location = '/dashboard/my-matches'
          }else{
            const pattern1 = /app.paddleparty\.com/;
            const pattern2 = /paddleparty-two\.vercel\.com/;

            if (pattern1.test(document.referrer) || pattern2.test(document.referrer)) {
              console.log({
                url: window.location,
                is_main : true
              })
              const currentUrl = new URL(document.referrer);
              currentUrl.searchParams.delete("token");
              window.history.replaceState(
                {},
                document.title,
                currentUrl.toString()
              );
              window.location = currentUrl
            }else{
              console.log({
                url: window.location,
                is_main : false
              })
              window.location = '/dashboard/my-matches'
            }
           
          }
          
        } else {
          setLoader(false);
          setMsg(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setMsg("Some Error Occured, Try Again.");
      });
  };

  function generateVerificationCode(length) {
    const charset = "0123456789"; // Characters to use for the verification code
    let verificationCode = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      verificationCode += charset[randomIndex];
    }

    console.log(verificationCode);

    return verificationCode;
  }

  const verifyEmail = async () => {
    setMsg(null)
    setLoader(true);
    let token = generateVerificationCode(6);
    console.log(email);
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + "/verify-user/", {
        email :  email.toLowerCase(),
        token,
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          localStorage.setItem("token", token);
          localStorage.setItem("email",  email.toLowerCase());
          setLoader(false);
          setStep(2);
        } else {
          setLoader(false);
          setMsg(res.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setMsg("Some Error Occured, Try Again.");
      });
  };



  const loginWithEmail = async (req, res) => {
   
    if (verificationCode === localStorage.getItem("token")) {
      let email = localStorage.getItem('email');
      console.log(email)
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + "/login_with_email/", {
          email,
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            localStorage.setItem("user", JSON.stringify(res.data.user));
            if(window.location == document.referrer){
              window.location.pathname ='/dashboard/my-matches';
            }else{
              window.location = document.referrer;
            }
          } else {
            setLoader(false);
            setMsg(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          setMsg("Some Error Occured, Try Again.");
        });
    } else {
      setLoader(false);
      setMsg("Authentication code incorrect.");
    }
  };

  useEffect(() => {
    if (token) {
      loginWithToken();
    } else {
      if (user) {
        setTimeout(() => {
          if(window.location == document.referrer){
            window.location.pathname ='/dashboard/my-matches';
          }else{
            const pattern1 = /app.paddleparty\.com/; 
            const pattern2 = /paddleparty-two\.vercel\.com/;
            if (pattern1.test(document.referrer) || pattern2.test(document.referrer)) {
              console.log({
                url: window.location,
                is_main : true
              })
              const currentUrl = new URL(document.referrer);
              currentUrl.searchParams.delete("token");
              window.history.replaceState(
                {},
                document.title,
                currentUrl.toString()
              );
              window.location = currentUrl
            }else{
              console.log({
                url: window.location,
                is_main : false
              })
              window.location = '/dashboard/my-matches'
            }
          }
        }, 2000);
      } else {
        setLoader(false);
      }
    }
  }, []);

  return (
    <div className="flex justify-center">
      <div className="lg:w-[300px] w-full px-10 lg:px-0">
        <div className=" lg:mt-[100px] mt-[70px] mb-[25px]">
          <img src="/logo.png" className=" w-[100%] h-[140px]  " />
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className="mb-[45px]">
              <div className="text-center text-[22px] font-[600]  text-[#244F62]">
                Welcome to Pickleball
              </div>
              <div className="text-center text-[22px] font-[600]  text-[#244F62]">
                Players League
              </div>
            </div>

            {step === 1 ? (
              <div>
                <div className="text-left  text-[16px] font-[400]  text-[#244F62] mb-[10px]">
                  Enter your email address to log in
                </div>

                <div className="">
                  <InputBase
                    sx={{
                      width: "100%",
                      border: "1px solid #12AFC2",
                      bgcolor: "rgba(18,175,194,0.02)",
                      borderRadius: "5px",
                      pl: 2,
                      py: "1px",
                    }}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                {msg && (
                  <div className="text-red-900 text-[14px] text-center mt-3">
                    {msg}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div className="text-left w-[300px]  text-[16px] font-[400]  text-[#244F62] mb-[10px]">
                  Enter the verification code we just sent to {' '}
                  {email.toLowerCase()}
                </div>

                <div className="">
                  <InputBase
                    sx={{
                      width: "100%",
                      border: "1px solid #12AFC2",
                      bgcolor: "rgba(18,175,194,0.02)",
                      borderRadius: "5px",
                      pl: 2,
                      py: "1px",
                    }}
                    value={verificationCode}
                    onChange={(e) => {
                      setVerificationCode(e.target.value);
                    }}
                  />
                </div>

                {msg && (
                  <div className="text-red-900 text-[14px] text-center mt-3">
                    {msg}
                  </div>
                )}
              </div>
            )}

            <div className=" flex justify-center mt-[50px]">
              <Button
                sx={{
                  bgcolor: "#44A093",
                  color: "white",
                  width: "150px",
                  textTransform: "none",
                  borderRadius: "5px",
                  fontSize: "15px",
                  ":hover": {
                    bgcolor: "#44A093",
                  },
                }}
                onClick={() => {
                  if (step === 1) {
                    verifyEmail();
                  }

                  if (step === 2) {
                    loginWithEmail();
                  }
                }}
              >
                Next
              </Button>
            </div>

            <div className="mt-[35px]">
              <div className="text-center text-[16px] font-[400]  text-[#244F62] mb-[10px]">
                For support, please reach out via email to
              </div>
              <div className="text-center underline relative bottom-3 text-[16px] font-[400]  text-[#44A093] mb-[10px]"
              onClick={()=> {
                window.location.href =  `mailto:hello@paddleparty.com`
              }}
              >
                hello@paddleparty.com
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
