import { Button, Drawer } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SideBar = ({open, setOpen}) => {
    const navigate = useNavigate()
    
  return (
    <Drawer open={open} onClose={()=> setOpen(false)}  >
        <div className="lg:col-span-1 h-full  bg-white px-2   space-y-4  shadow-md   flex flex-col pb-4 ">
        {window.location.pathname === "/dashboard/my-matches" ? (
            <div
            onClick={()=>{
                setOpen(false)
                navigate("/dashboard/my-matches")
             }}
              className={
                "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
              }
            >
              <img src="/icon1b.png" className="w-[34px] h-[34px]" />{" "}
              <div>My Matches</div>
            </div>
          ) : (
            <div
            onClick={()=>{
                setOpen(false)
                navigate("/dashboard/my-matches")
             }}
              className={
                "text-[20px] text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
              }
            >
              <img src="/icon1a.png" className="w-[34px] h-[34px]" />{" "}
              <div>My Matches</div>
            </div>
          )}

          {window.location.pathname === "/dashboard/submit-scores" ? (
            <div
            onClick={()=>{
                setOpen(false)
                navigate("/dashboard/submit-scores")
             }}
              className={
                "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
              }
            >
              <img src="/icon2b.png" className="w-[19px] h-[24px]" />{" "}
              <div>Submit Scores</div>
            </div>
          ) : (
            <div 
            onClick={()=>{
                setOpen(false)
                navigate("/dashboard/submit-scores")
             }}
            className="text-[20px] text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer">
              <img src="/icon2a.png" className="w-[19px] h-[24px]" />{" "}
              <div>Submit Scores</div>
            </div>
          )}

          {window.location.pathname === "/dashboard/standings" ? (
            <div
             onClick={()=>{
                setOpen(false)
                navigate("/dashboard/standings")
             }}
              className={
                "text-[20px] text-[#12AFC2] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer"
              }
            >
              <img src="/icon3b.png" className="w-[19px] h-[24px]" />{" "}
              <div> Standings</div>
            </div>
          ) : (
            <div 
            onClick={()=>{
                setOpen(false)
                navigate("/dashboard/standings")
             }}
            className="text-[20px]  text-[#B1B1B1] hover:bg-[rgba(18,175,194,0.08)] flex items-center space-x-2  px-4 py-2 cursor-pointer">
              
              <img src="/icon3a.png" className="w-[19px] h-[24px]" />{" "}
              <div> Standings</div>
            </div>
          )}


          <div className="flex-1"></div>

          <div className=" flex justify-center pb-5   ">
            <Button
              sx={{
                bgcolor: "#44A093",
                color: "white",
                width: "150px",
                textTransform: "none",
                borderRadius: "5px",
                fontSize: "15px",
                ":hover": {
                  bgcolor: "#44A093",
                },
              }}
              onClick={()=>{
                localStorage.removeItem('user')
                window.location.pathname = '/'
              }}
            >
              Log Out
            </Button>
          </div>
        </div>
    </Drawer>
  )
}

export default SideBar