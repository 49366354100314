import { Avatar, AvatarGroup, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import ReactHelmet from 'react-helmet';

const MyMatches = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [matches, setMatches] = useState();
  const navigate = useNavigate();
  const getMatches = async () => {
    console.log(user.team_id)
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "/get-matches/" + user.team_id)
      .then((res) => {
        setMatches(res.data.matches);
        console.log(res.data)
        console.log(res.data.matches);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      getMatches();
    }
  }, []);
  const avatarStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    overflow: "hidden",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div className="bg-white rounded-[30px]   overflow-auto lg:w-[700px] px-4 my-8 ">
      <ReactHelmet>
        <title>My Matches - Paddle Party</title>
      </ReactHelmet>
      <div className="text-center lg:text-[40px] text-[22px] font-[600] text-[#06B3D2] my-4 lg:my-0">
        My Matches
      </div>

      {matches && matches.length > 1 && (
        <div className="hidden lg:grid grid-cols-12 w-full my-3 items-center">
          <div className="col-span-2"></div>
          <div className="col-span-3 text-[18px] text-[#06B3D2] font-[400]">
            Team
          </div>
          <div className="col-span-3 text-[18px] text-[#06B3D2] font-[400]">
            Score
          </div>
          <div className="col-span-2 text-[18px] text-[#06B3D2] font-[400]">
            Result
          </div>
          <div className="col-span-1"></div>
          <div className="col-span-1"></div>
        </div>
      )}

      {matches &&
        matches.map((item, index) => {
          let other_team_id = item.teams
            .filter((e) => e.team_id !== user.team_id)
            .map((e) => e.team_id);
          console.log(other_team_id[0]);
          const getTeam1 = async () => {
            await axios
              .get(
                process.env.REACT_APP_BACKEND_URL +
                  "/get-team/" +
                  other_team_id[0]
              )
              .then((res) => {
                document.getElementById(index + "-1").src =
                  res.data.team.player1.avatar || "/generic.jpeg";
                document.getElementById(index + "-2").src =
                  res.data.team.player2.avatar || "/generic.jpeg";
                document.getElementById(index + "-1").alt =
                  res.data.team.player1.first_name;
                document.getElementById(index + "-2").alt =
                  res.data.team.player2.first_name;
                if (
                  !res.data.team.matches_ ||
                  res.data.team.matches_.length < 1
                ) {
                  document.getElementById("ranking" + index).innerHTML = "UR";
                  document.getElementById("rankingsm" + index).innerHTML = "UR";
                }
              })
              .catch((err) => {
                console.log(err);
              });
          };
          getTeam1();
          return (
            <div className="group">
              <div className="grid lg:grid-cols-12 grid-cols-9 gap-4 lg:gap-2 w-full items-center cursor-pointer  ">
                <div className="col-span-2 flex items-center">
                  <div className="text-[16px] text-[#06B3D2] font-[500]">
                    {item.week_number}.
                  </div>
                  <div className="flex space-x-[-20px] lg:space-x-[-10px]">
                    <div style={avatarStyle}>
                      <img
                        onError={(e) => {
                          e.target.src = "/generic.jpeg";
                        }}
                        id={index + "-1"}
                        src={""}
                        alt="Avatar"
                        style={imageStyle}
                      />
                    </div>
                    <div style={avatarStyle}>
                      <img
                        onError={(e) => {
                          e.target.src = "/generic.jpeg";
                        }}
                        id={index + "-2"}
                        src={""}
                        alt="Avatar"
                        style={imageStyle}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-span-3 text-[20px] hidden lg:block text-[#244F62] font-[400]"
                  onClick={() => {
                    navigate("/dashboard/match/" + item._id);
                  }}
                >
                  {item.teams
                    .filter((e) => e.team_id !== user.team_id)
                    .map((e) =>
                      e.team_name.length > 14
                        ? `${e.team_name.slice(0, 14)}...`
                        : e.team_name
                    )}{" "}
                  <span id={"ranking" + index}></span>
                </div>
                <div
                  className="col-span-3 text-[20px] hidden lg:block text-[#244F62] font-[400]"
                  onClick={() => {
                    navigate("/dashboard/match/" + item._id);
                  }}
                >
                  {!item.is_forfeit && item.score
                    ? item.score.map((item, index) => {
                        let otherTeamId = Object.keys(item.game1).find(
                          (teamId) => teamId != user.team_id
                        );

                        return (
                          <>
                            <>
                              {item.game1 &&
                                item.game1[user.team_id] +
                                  "-" +
                                  item.game1[otherTeamId]}
                            </>
                            <>
                              {item.game2 &&
                                ", " +
                                  item.game2[user.team_id] +
                                  "- " +
                                  item.game2[otherTeamId]}
                            </>
                            <>
                              {item.game3 &&
                                ", " +
                                  item.game3[user.team_id] +
                                  "-" +
                                  item.game3[otherTeamId]}
                            </>
                          </>
                        );
                      })
                    : ""}

                  {item.is_forfeit && "Forfeit"}
                </div>

                <div className="col-span-5 lg:text-[24px] text-[18px] pl-2 lg:hidden text-[#244F62] font-[400]">
                  <div
                    onClick={() => {
                      let id = item.teams
                        .filter((e) => e.team_id !== user.team_id)
                        .map((e) => e.team_id);

                      navigate("/dashboard/match/" + item._id);
                    }}
                  >
                    {" "}
                    {item.teams
                      .filter((e) => e.team_id !== user.team_id)
                      .map((e) =>
                        e.team_name.length > 14
                          ? `${e.team_name.slice(0, 14)}...`
                          : e.team_name
                      )}{" "}
                    <span id={"rankingsm" + index}></span>
                  </div>
                  <div
                    className="text-[16px]"
                    onClick={() => {
                      navigate("/dashboard/match/" + item._id);
                    }}
                  >
                    {!item.is_forfeit && item.score
                      ? item.score.map((item, index) => {
                          let otherTeamId = Object.keys(item.game1).find(
                            (teamId) => teamId != user.team_id
                          );

                          return (
                            <>
                              <>
                                {item.game1 &&
                                  item.game1[user.team_id] +
                                    "-" +
                                    item.game1[otherTeamId]}
                              </>
                              <>
                                {item.game2 &&
                                  ", " +
                                    item.game2[user.team_id] +
                                    "- " +
                                    item.game2[otherTeamId]}
                              </>
                              <>
                                {item.game3 &&
                                  ", " +
                                    item.game3[user.team_id] +
                                    "-" +
                                    item.game3[otherTeamId]}
                              </>
                            </>
                          );
                        })
                      : ""}

                    {item.is_forfeit && "Forfeit"}
                  </div>
                </div>
                <div className="col-span-2 lg:text-[18px] text-[12px] text-white font-[500]">
                  {item.winning_team &&
                    item.winning_team === user.team_id &&
                    !item.is_forfeit && (
                      <div className="bg-[#3DB44B] w-fit rounded-[5px] px-4 ">
                        Win
                      </div>
                    )}

                  {item.winning_team &&
                    item.winning_team === user.team_id &&
                    item.is_forfeit && (
                      <div className="bg-[#45818E] w-fit rounded-[5px] px-4 ">
                        Win
                      </div>
                    )}

                  {item.winning_team &&
                    item.winning_team != user.team_id &&
                    !item.is_forfeit && (
                      <div className="bg-[#CC0000] w-fit rounded-[5px] px-4 ">
                        Loss
                      </div>
                    )}

                  {item.winning_team &&
                    item.winning_team != user.team_id &&
                    item.is_forfeit && (
                      <div className="bg-[#CC0000] w-fit rounded-[5px] px-4 ">
                        Loss
                      </div>
                    )}

                  {!item.is_forfeit && !item.winning_team && (
                    <div className="bg-[#E6E6E6] w-fit rounded-[5px] px-2 ">
                      Unplayed
                    </div>
                  )}

                  {item.is_forfeit && !item.winning_team && (
                    <div className="bg-[#9c9a9a] w-fit rounded-[5px] px-2 ">
                      Unplayed
                    </div>
                  )}
                </div>
                <div
                  className="col-span-1 hidden lg:block"
                  onClick={() => {
                    window.open(item.chat_url, "_blank");
                  }}
                >
                  {" "}
                  <img
                    src="/chat.png"
                    className="w-[38px] h-[38px] cursor-pointer"
                  />{" "}
                </div>
                <div
                  className="col-span-1 hidden lg:block"
                  onClick={() => {
                    let id = item.teams
                      .filter((e) => e.team_id !== user.team_id)
                      .map((e) => e.team_id);

                    navigate("/dashboard/team/" + id);
                  }}
                >
                  {" "}
                  <img
                    src="/more.png"
                    className="w-[33px] h-[33px] cursor-pointer"
                  />{" "}
                </div>
              </div>

              <Divider sx={{ my: 1 }} className="" />
            </div>
          );
        })}

      {!matches && (
        <div className=" text-center text-[14px] lg:text-[19px]">
          {" "}
          We're still working on building out your perfect season. Matches are
          expected to be available soon!{" "}
        </div>
      )}
    </div>
  );
};

export default MyMatches;
